<template>
	<div :class=" mainNavIsHidden ? 'main-nav-hidden-status-container' : 'status-container'">
		<IdCheckSummary
			class="center-grid"
			:serverRules="serverRules"
			:playerState="playerState"
			:isMobile="isMobile"
			:languageStrings="languageStrings"
			:languageErrorStrings="languageErrorStrings"
		/>
		<div class="button-container" v-if="devMode">
			<button @click="clearIdCheckResults()" class="btn extra-padding">{{ languageStrings.clearIdCheckResults }}</button>
		</div>
		<div v-if="playerState?.idCheckSummary?.status !== 'Success' || playerState?.idCheckSummary?.status !== 'NotNeeded'">
			<p class="disclaimer">
				{{ languageStrings.identityDisclaimer }}
			</p>
			<div class="button-container">
				<button @click="acceptIdCheckStart = true" class="btn extra-padding">{{ languageStrings.beginIdCheck }}</button>
			</div>
			<NeoCheckSubmitVideoID
				v-if="acceptIdCheckStart"
				:languageStrings="languageStrings"
				:languageErrorStrings="languageErrorStrings"
				:playerState="playerState"
				:serverRules="serverRules"
				:isMobile="isMobile"
			/>
		</div>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import { onBeforeUnmount } from "vue";
import router from "@/router";
import NeoCheckSubmitVideoID from "@/components/NeoCheckSubmitVideoID";
import IdCheckSummary from "@/components/IdCheckSummary";

export default {
	name: "IdentificationStatus",
	props: {
		languageStrings: Object,
		languageErrorStrings: Object,
		playerState: Object,
		serverRules: Object,
		isMobile: Boolean,
		mainNavIsHidden: Boolean,
	},
	components: {
		NeoCheckSubmitVideoID,
		IdCheckSummary,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			busyText: "",
			acceptIdCheckStart: false,
		};
	},
	methods: {
		async clearIdCheckResults() {
			// button that executes this function only shows up in dev mode
			// this function does post to notification array in app header but has no other return
			this.serverBusy = true;
			this.busyText = this.languageStrings.neoCheckClear;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let requestUrl = new URL("/api/v1/dev/idcheck/clear", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				headers: headerObj,
			});

			try {
				const response = await fetch(request).catch(() => {
					this.status.ok = false;
					this.status.message = this.languageErrorStrings.somethingWentWrongTryLater;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				});

				this.acceptIdCheckStart = false;

				this.status.ok = true;
				this.status.message = this.languageErrorStrings.idCheckCleared;
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("updateIdCheckSummary");
				this.busyText = "";
				this.serverBusy = false;
			} catch (e) {
				console.error(e);
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				this.busyText = "";
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.disclaimer {
	text-align: center;
	width: 80%;
	margin: auto;
}
.button-container {
	display: block;
	margin: auto;
	text-align: center;
}

.status-container {
	margin-top: 110px;
	color: black;
}

.main-nav-hidden-status-container {
	margin-top: 116px;
	color: black;
}

#main-nav-hidden {
	height: 100vh;
}

button {
	border: transparent;
}

@media (max-width:757px) {

	button {
		width: 70%;
	}

	p {
		padding: 10px;
	}

	.main-nav-hidden-status-container {
		margin-top: 95px;
	}
}
</style>
